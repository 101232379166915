<template>
  <div class="file-list-container">
    <CDataTable :items="files" :fields="fileListFields" striped>
      <template #fileType>
        <td>
          <MIcon name="fa-file" />
        </td>
      </template>
      <template #displayTitle="{ item }">
        <td>
          <FileLink :file="item" />
        </td>
      </template>
      <template #updatedBy="{ item }">
        <td>{{ item.updatedBy || item.createdBy }}</td>
      </template>
      <template #updatedOn="{ item }">
        <td>{{ $format.formatDate(item.updatedOn || item.createdOn) }}</td>
      </template>
    </CDataTable>
  </div>
</template>
<style lang="scss">
.file-list-container {
  height: 250px;
  overflow-y: auto;
}
</style>
<script>
import FileLink from '@/components/FileLink';
const ALL_FILELIST_FIELDS = [
  {
    key: 'fileType',
    label: '',
  },
  {
    key: 'displayTitle',
    label: 'File',
  },
  {
    key: 'updatedBy',
    label: 'User',
  },
  {
    key: 'updatedOn',
    label: 'Updated',
  },
];

const NO_USER_FIELDS = [
  {
    key: 'fileType',
    label: '',
  },
  {
    key: 'displayTitle',
    label: 'File',
  },
  {
    key: 'updatedOn',
    label: 'Updated',
  },
];

export default {
  name: 'FileListTable',
  components: {
    FileLink,
  },
  props: {
    hideUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    files: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasFiles() {
      return this.files && this.files.length > 0;
    },
    fileListFields() {
      if (this.hideUser) {
        return NO_USER_FIELDS;
      }
      return ALL_FILELIST_FIELDS;
    },
  },
  methods: {
    downloadFile(file) {
      this.$store
        .dispatch('tasks/downloadFile', { file })
        .then((res) => {
          this.errors = null;
          this.submitted = false;
          let data = res.data;
          let headers = res['headers'];
          let blob = new Blob([data], { type: headers['content-type'] });
          let downloadUrl = window.URL.createObjectURL(blob);
          window.open(downloadUrl, '_self', '', false);
        })
        .catch((error) => {
          this.submitted = true;
          this.errors = error.response.data.fields;
          this.$swal.fire({
            title: 'Error',
            text: 'The file does not exist or could not be downloaded.  Please contact an administrator or try again.',
            icon: 'error',
          });
        })
        .finally(() => {
          //this.isSaving = false;
          //this.saveEnabled = true;
        });
    },
  },
};
</script>
