<template>
  <div v-if="isLoading">
    <CCard>
      <CCardBody>
        <h2>
          Loading... Please wait...
          <CSpinner
            color="primary"
            style="
              width: 2rem;
              height: 2rem;
              border: 0.125em solid currentColor;
              border-right-color: transparent;
              margin-left: 10px;
            "
          />
        </h2>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="hasLoadingError">
    <CCard>
      <CCardHeader>
        <h2>Error</h2>
      </CCardHeader>
      <CCardBody>
        An Error occurred while trying to load this client.
        <!--
        -->
        Please see your system administrator to resolve the issue.
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="!isDataFetched">
    <CCard>
      <CCardHeader>
        <h2>Client {{ name }} Not Found</h2>
      </CCardHeader>
      <CCardBody>
        This client with code "{{ name }}" could not be loaded.
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol>
                <h2>Client Overview: {{ client.clientName }}</h2>
              </CCol>
              <CCol class="text-right" col="2">
                <CSelect
                  class="mb-0"
                  label="Actions:"
                  :options="clientActions"
                  :value.sync="clientAction"
                  placeholder="Choose Action"
                  horizontal
                  @change="handleActionChoice"
                />
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol>
                <CProgress
                  :value="currentProgress"
                  height="30px"
                  color="info"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol class="text-right"
                >Project Completion: {{ currentProgress.toFixed(0) }}%</CCol
              >
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol>
                <h2>Recent Client Activities</h2>
              </CCol>
              <!--
              <CCol class="text-right">
                <span>View All:&nbsp;</span>
                <CSwitch
                  class="pt-2"
                  value="info"
                  color="info"
                  v-bind="{ 'shape': 'pill' }"
                  :checked="showAllTasks"
                  @update:checked="showAllTasks = !showAllTasks"
                />
              </CCol>
              -->
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow class="pb-4">
              <CCol>
                Listed below are all of the previously completed users tasks, as
                well as the tasks that are scheduled, but are yet to be
                completed.
                <br />If any tasks are currently processing they will be shown,
                whether they are a user task or not.
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CDataTable
                  :items="recentActivities"
                  :fields="recentActivityFields"
                  :items-per-page="5"
                  pagination
                  striped
                  class="client-activities-table"
                >
                  <template #status="{ item }">
                    <td>
                      <MIcon
                        v-if="item.statusIcon"
                        :name="item.statusIcon.name"
                        :class="item.statusIcon.color"
                      />
                    </td>
                  </template>
                  <template #title="{ item }">
                    <td>
                      <router-link
                        v-if="item.taskId"
                        :to="{ name: 'view-task', params: { id: item.taskId } }"
                        >{{ item.title }}</router-link
                      >
                      <div v-else>{{ item.title }}</div>
                    </td>
                  </template>
                  <template #action="{ item }">
                    <td>{{ item.action }}</td>
                  </template>
                  <template #user="{ item }">
                    <td>{{ item.userFullName }}</td>
                  </template>
                  <template #endTime="{ item }">
                    <td>{{ $format.formatTimestamp(item.taskTime) }}</td>
                  </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <CCardGroup deck>
          <CCard>
            <CCardHeader>
              <h2>Client Files</h2>
            </CCardHeader>
            <CCardBody>
              <CTabs variant="pills">
                <CTab active>
                  <template #title>
                    Reports
                    <CBadge color="info" shape="pill">{{
                      reportFiles ? reportFiles.length : '0'
                    }}</CBadge>
                  </template>
                  <FileListTable :files="reportFiles" hide-user />
                </CTab>
                <CTab>
                  <template #title>
                    User Uploads
                    <CBadge color="info" shape="pill">{{
                      userUploadFiles ? userUploadFiles.length : '0'
                    }}</CBadge>
                  </template>
                  <FileListTable :files="userUploadFiles" />
                </CTab>
                <CTab>
                  <template #title>
                    Templates
                    <CBadge color="info" shape="pill">{{
                      templateFiles ? templateFiles.length : '0'
                    }}</CBadge>
                  </template>
                  <FileListTable :files="templateFiles" hide-user />
                </CTab>
                <!-- <CTab>
                  <template #title>
                    My Files
                    <CBadge color="info" shape="pill">{{ userFiles ? userFiles.length : '0' }}</CBadge>
                  </template>
                  <FileListTable :files="userFiles" />
                </CTab>-->
              </CTabs>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader>
              <h2>My Client Activities</h2>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="myActivities"
                :fields="myActivityFields"
                striped
              >
                <template #no-items-view>
                  <div class="text-center">
                    <h2 class="text-success">NO CURRENT ACTIVITIES</h2>
                    <h4 class="text-muted">
                      We can't find any active tasks for this client.
                    </h4>
                  </div>
                </template>
                <template #title="{ item }">
                  <td>
                    <router-link
                      v-if="item.taskId"
                      :to="{ name: 'view-task', params: { id: item.taskId } }"
                      >{{ item.title }}</router-link
                    >
                    <div v-else>{{ item.title }}</div>
                  </td>
                </template>
                <template #taskTime="{ item }">
                  <td>{{ $format.formatTimestamp(item.taskTime) }}</td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
    <BuildKitDownloadModal :client-code="name" :show.sync="showDownloadModal" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { entityLoadingMixin } from '@/mixins';
import WorkflowTask from '@/WorkflowTask';
import FileListTable from './components/FileListTable';
import BuildKitDownloadModal from './components/BuildKitDownloadModal';
import { VUE_APP_PRODUCT_MAPPER_ENABLED } from '@/utils/env';
import api from '@/api';

const CLIENT_ACTIONS = [
  {
    label: 'Product Mapping',
    value: 'PM',
  },
  {
    label: 'Run Ad-Hoc Tests',
    value: 'ADHOC',
  },
];

const ACTIVITY_FIELDS = [
  {
    key: 'status',
    label: '',
  },
  {
    key: 'title',
    label: 'Task',
  },
  {
    key: 'action',
    label: 'Status',
  },
  {
    key: 'user',
    label: 'Assigned',
  },
  {
    key: 'endTime',
    label: 'Date',
  },
];

const MY_ACTIVITY_FIELDS = [
  {
    key: 'phase',
    label: 'Phase',
  },
  {
    key: 'title',
    label: 'Task',
  },
  {
    key: 'taskTime',
    label: '',
  },
];

const FILELIST_FIELDS = [
  {
    key: 'fileType',
    label: '',
  },
  {
    key: 'fileName',
    label: 'File',
  },
  {
    key: 'updatedOn',
    label: 'Updated',
  },
];

export default {
  name: 'ClientOverview',
  components: {
    FileListTable,
    BuildKitDownloadModal,
  },
  mixins: [entityLoadingMixin],
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAllTasks: false,
      dispatchPath: 'clients/fetchClientOverview',
      clientAction: null,
      hasSbxCompanies: false,
      showDownloadModal: false,
    };
  },
  watch: {
    showDownloadModal(v) {
      if (!v) {
        this.clientAction = null;
      }
    },
    name(v) {
      if (!v) {
        this.hasSbxCompanies = false;
      } else {
        this.checkForSbxCompanies(v);
      }
    },
    $route(v) {
      if (this.name) {
        this.checkForSbxCompanies(this.name);
      } else {
        this.hasSbxCompanies = false;
      }
    },
  },
  mounted() {
    if (this.name) {
      this.checkForSbxCompanies(this.name);
    } else {
      this.hasSbxCompanies = false;
    }
  },
  computed: {
    ...mapGetters('tasks', ['activities']),
    client() {
      return this.fetchedData;
    },
    recentActivities() {
      let clientActivities = this.client.recentActivity.filter(
        (task) => task.clientCode === this.name
      );
      let wfTaskList = clientActivities.map((t) => {
        return WorkflowTask.fromTaskDTO(t);
      });
      return wfTaskList;
    },
    currentProgress() {
      if (!this.client) return 0;
      return this.client.onboardingCompletionPct || 0;
    },
    recentActivityFields() {
      return ACTIVITY_FIELDS;
    },
    fileListFields() {
      return FILELIST_FIELDS;
    },
    journeyFiles() {
      if (this.client.fileCollections.length === 0) return;
      return (
        this.client.fileCollections.find((fc) => fc.name === 'Journey').files ||
        []
      );
    },
    reportFiles() {
      if (this.client.fileCollections.length === 0) return;
      return (
        this.client.fileCollections.find((fc) => fc.name === 'Reports').files ||
        []
      );
    },
    userUploadFiles() {
      if (this.client.fileCollections.length === 0) return;
      return (
        this.client.fileCollections.find((fc) => fc.name === 'User Uploads')
          .files || []
      );
    },
    templateFiles() {
      if (this.client.fileCollections.length === 0) return;
      return (
        this.client.fileCollections.find((fc) => fc.name === 'Templates')
          .files || []
      );
    },
    userFiles() {
      return this.client.userFiles || [];
    },
    myActivityFields() {
      return MY_ACTIVITY_FIELDS;
    },
    myActivities() {
      return this.activities.filter((task) => {
        return task.clientCode === this.client.clientCode;
      });
    },
    clientActions() {
      let actions = [];
      if (VUE_APP_PRODUCT_MAPPER_ENABLED) {
        actions = CLIENT_ACTIONS;
      } else {
        actions = [CLIENT_ACTIONS[1]];
      }
      if (this.hasSbxCompanies) {
        actions.push({
          label: 'Download BuildKit',
          value: 'BUILDKIT',
        });
      }

      return actions;
    },
  },
  methods: {
    checkForSbxCompanies(v) {
      return new Promise((resolve) => {
        api.clients
          .hasSbxCompanies(v)
          .then((r) => {
            this.hasSbxCompanies = r === true;
            resolve(r === true);
          })
          .catch(() => {
            this.hasSbxCompanies = false;
            resolve(false);
          });
      });
    },
    getStatusObject(task) {
      let obj = {
        variant: null,
        status: null,
      };
      switch (task.status) {
        case 'IN_PROGRESS':
          obj.status = 'fa-arrow-circle-right';
          break;
        case 'CANCELED':
          obj.variant = 'danger';
          obj.status = 'fa-x-circle';
          break;
        case 'COMPLETED':
          obj.variant = 'success';
          obj.status = 'fa-check';
          break;
        case 'SKIPPED':
          obj.variant = 'info';
          obj.status = 'fa-list-low-priority';
          break;
        case 'FAILED':
          obj.variant = 'danger';
          obj.status = 'fa-exclamation-triangle';
          break;
        case 'SCHEDULED':
          obj.status = 'fa-clock-o';
          break;
        case 'TIMED_OUT':
          obj.variant = 'warning';
          obj.status = 'fa-warning';
          break;
        case 'READY_FOR_RERUN':
          obj.variant = 'info';
          obj.status = 'fa-sync';
          break;
      }
      return obj;
    },
    handleActionChoice() {
      if (!this.clientAction) return;
      switch (this.clientAction) {
        case 'BUILDKIT':
          this.showDownloadModal = true;
          break;
        case 'RESTART':
          this.$swal.fire({
            title: 'Oops!',
            text: 'Not Yet Implemented',
            icon: 'info',
          });
          break;
        case 'ADHOC':
          this.$router.push({
            name: 'run-adhoc-tests',
            params: { clientCode: this.client.clientCode },
          });
          break;
        case 'PM':
          window.location.assign(
            'http://' +
              window.location.host +
              `/pma/#/?clientCode=${this.client.clientCode}`
          );
          break;
      }
    },
  },
};
</script>
<style lang="scss">
.client-activities-table {
  .table-responsive {
    height: 300px;
    overflow-y: auto;
  }
}
</style>
