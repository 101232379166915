<template>
  <CModal :show.sync="computedShow" title="BuildKit Download" centered>
    <template #footer>
      <CButton color="secondary" @click="close">Cancel</CButton>
    </template>
    <p>
      Generate and download a BuildKit for any of the following partitions or
      virtual environments
    </p>
    <CRow>
      <CCol col="6">
        <CSelect :options="options" :value.sync="partition" />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <DownloadLink
          :url="downloadUrl"
          :params="downloadParams"
          :disabled="!partition"
          color="primary"
          block
          title="Download"
          button
          @completed="handleCompleted"
        />
      </CCol>
    </CRow>
  </CModal>
</template>
<script>
import DownloadLink from '@/components/DownloadLink';
export default {
  name: 'BuildKitDownloadModal',
  components: {
    DownloadLink,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    clientCode: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      internalShow: this.show === true,
      downloadUrl: '/api/sbx/sdi/download',
      partition: 'PRD',
      options: [
        {
          label: 'Production',
          value: 'PRD',
        },
        {
          label: 'Development',
          value: 'DEV',
        },
        {
          label: 'Lab / Test',
          value: 'LAB',
        },
      ],
    };
  },
  computed: {
    downloadParams() {
      return {
        clientCode: this.clientCode,
        partition: this.partition,
      };
    },
    computedShow: {
      get() {
        return this.internalShow;
      },
      set(v) {
        this.internalShow = v;
        this.$emit('update:show', v);
      },
    },
  },
  watch: {
    show(v) {
      this.internalShow = v === true;
    },
  },
  methods: {
    close() {
      this.computedShow = false;
    },
    handleCompleted(status) {
      if (status !== 'error') {
        this.computedShow = false;
      }
    },
  },
};
</script>
